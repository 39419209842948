export enum ProductTypes {
  LoaderCranes = '001',
  Railway = '002',
  AccessPlatforms = '003',
  TruckMountedForklifts = '004',
  TailLifts = '006',
  ContainerHandlingSystems = '007',
  EpsilonTimberAndRecycling = '010',
  Skiploader = '011',
  MarineCranes = '013',
  WindCranes = '026',
  EpsilonOffRoad = '030',
  ServiceCranes = '031',
  TruckBodies = '032',
  PALPROMechanicTrucks = '034',
  LargeAerialPlatforms = '035',
  CableHoists = '036',
  Hoists = '037',
  PickUpLifts = '039',
  AccessPlatformPPISmart = '040',
  CrawlerCrane = '043',
  BusLift = '044',
  BridgeInspectionUnits = '045',
  OffshoreCranes = '046',
  LifeSavingEquipment = '047',
  LifeAndRescueBoats = '048',
  Winches = '049',
  ForestryAndScrapCranes = '050',
  Inman = '052',
  ETISolutions = '053',
  Davits = '054',
}
